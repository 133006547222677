<template>
  <section class="projects">
    <div class="container">
      <img alt="projects" src="../assets/images/projects/projects-title.png" class="projects-title">
      <div class="projects-content">
        <div class="project-card" @mouseover="hoverOver" @mouseout="hoverOut">
          <img src="../assets/images/projects/sirca.jpg" alt="Sirca" class="project-image">
          <div :class="classes">  
            <h3>Sirca</h3>
            <h4>Web + App Development + Marketing</h4>
            <p>A mobile navigation app catered to exploring destinations along your current route.</p>
          </div>
        </div>

        <div class="project-card" @mouseover="hoverOver" @mouseout="hoverOut">
          <img src="../assets/images/projects/patel-brothers.jpg" alt="Patel Brothers" class="project-image">
          <div :class="classes">  
            <h3>Patel Brothers</h3>
            <h4>Web Development + Content </h4>
            <p>Largest South Asian supermarket looked to create an e-commerce platform to simplify Curbside Pickup for multiple locations in one site.</p>
          </div>
        </div>

        <div class="project-card" @mouseover="hoverOver" @mouseout="hoverOut">
          <img src="../assets/images/projects/swad.jpg" alt="Swad" class="project-image">
          <div :class="classes">
            <h3>Swad</h3>
            <h4>Web + App Development</h4>
            <p>Largest South Asian food brand in the U.S. looked to create a branding website and mobile application.</p>
          </div>
        </div>

        <div class="project-card" @mouseover="hoverOver" @mouseout="hoverOut">
          <img src="../assets/images/projects/neurala.jpg" alt="Neurala" class="project-image">
          <div :class="classes">
            <h3>Neurala</h3>
            <h4>Web Development</h4>
            <p>An AI solution platform required updates to their marketing website.</p>
          </div>
        </div>

        <div class="project-card" @mouseover="hoverOver" @mouseout="hoverOut">
          <img src="../assets/images/projects/bards.jpg" alt="Bards of Nevermore" class="project-image">
          <div :class="classes">
            <h3>Bards of Nevermore</h3>
            <h4>Web Development</h4>
            <p>A creative ad agency looked to rebrand and build their website.</p>
          </div>
        </div>

        <div class="project-card" @mouseover="hoverOver" @mouseout="hoverOut">
          <img src="../assets/images/projects/thebosco.jpg" alt="The Bosco" class="project-image">
          <div :class="classes">
            <h3>The Bosco</h3>
            <h4>App Development</h4>
            <p>A social media photo and gif experience agency looked to update and enhance their iPad photobooth experience.</p>
          </div>
        </div>

        <div class="project-card" @mouseover="hoverOver" @mouseout="hoverOut">
          <img src="../assets/images/projects/ycb.jpg" alt="Yoga Club Boston" class="project-image">
          <div :class="classes">
            <h3>Yoga Club Boston</h3>
            <h4>Web: Node.JS + React</h4>
            <p>A small yoga studio looked to build out a new workout video content platform for online subscribers.</p>
          </div>
        </div>

        <div class="project-card" @mouseover="hoverOver" @mouseout="hoverOut">
          <img src="../assets/images/projects/cgpclothing.jpg" alt="CGP Clothing" class="project-image">
          <div :class="classes">
            <h3>CGP Clothing</h3>
            <h4>Mobile: Swift (iOS Only)</h4>
            <p>MMA-inspired clothing brand looked to create an iOS mobile e-commerce app.</p>
          </div>
        </div>

        <div class="project-card" @mouseover="hoverOver" @mouseout="hoverOut">
          <img src="../assets/images/projects/sirclepos.jpg" alt="SirclePOS" class="project-image">
          <div :class="classes">
            <h3>SirclePOS</h3>
            <h4>Mobile: Swift (iOS Only)</h4>
            <p>Android-based POS system looked to create a companion iOS application to handle administrative POS tasks.</p>
          </div>
        </div>

        <div class="project-card" @mouseover="hoverOver" @mouseout="hoverOut">
          <img src="../assets/images/projects/deane.jpg" alt="Deane Corporate Finance" class="project-image">
          <div :class="classes">
            <h3>Deane Corporate Finance</h3>
            <h4>Web Development</h4>
            <p>Financial consulting firm looked to rebrand and build their website.</p>
          </div>
        </div>

        <div class="project-card" @mouseover="hoverOver" @mouseout="hoverOut">
          <img src="../assets/images/projects/buffalo.jpg" alt="Buffalo and Associates" class="project-image">
          <div :class="classes">
            <h3>Buffalo &amp; Associates</h3>
            <h4>Web Development</h4>
            <p>Law firm looked to rebrand and build their website.</p>
          </div>
        </div>

        <div class="project-card" @mouseover="hoverOver" @mouseout="hoverOut">
          <img src="../assets/images/projects/premjyotish.jpg" alt="Prem Jyotish" class="project-image">
          <div :class="classes">
            <h3>Prem Jyotish</h3>
            <h4>Web Development + Marketing</h4>
            <p>Astrologist and numerologist looked to rebrand and rebuild the website.</p>
          </div>
        </div>

        <div class="project-card" @mouseover="hoverOver" @mouseout="hoverOut">
          <img src="../assets/images/projects/trimed.jpg" alt="TriMed" class="project-image">
          <div :class="classes">
            <h3>TriMed</h3>
            <h4>Web + App Development</h4>
            <p>Elder home care service provider looked to create platform to provide information to families.</p>
          </div>
        </div>

        <div class="project-card" @mouseover="hoverOver" @mouseout="hoverOut">
          <img src="../assets/images/projects/panoply.jpg" alt="Panoply By Sakshi" class="project-image">
          <div :class="classes">
            <h3>Panoply By Sakshi</h3>
            <h4>Web Development</h4>
            <p>Custom jeweler looked to brand and create the website.</p>
          </div>
        </div>

        <div class="project-card" @mouseover="hoverOver" @mouseout="hoverOut">
          <img src="../assets/images/projects/chaipos.jpg" alt="ChaiPOS" class="project-image">
          <div :class="classes">
            <h3>ChaiPOS</h3>
            <h4>Web + App Development</h4>
            <p>In-house project: POS system currently under closed beta testing.</p>
          </div>
        </div>

        <div class="project-card" @mouseover="hoverOver" @mouseout="hoverOut">
          <img src="../assets/images/projects/theplaylist.jpg" alt="thePlaylist" class="project-image">
          <div :class="classes">
            <h3>thePlaylist</h3>
            <h4>App Development</h4>
            <p>In-house project: Bi-weekly music app for iOS devices.</p>
          </div>
        </div>

        <div class="project-card" @mouseover="hoverOver" @mouseout="hoverOut">
          <img src="../assets/images/projects/wellnessbiscuit.jpg" alt="Wellness Biscuit" class="project-image">
          <div :class="classes">
            <h3>Wellness Biscuit</h3>
            <h4>Web Development</h4>
            <p>In-house project: Website and brand templates for fitness professionals and studios.</p>
          </div>
        </div>

        <div class="project-card" @mouseover="hoverOver" @mouseout="hoverOut">
          <img src="../assets/images/projects/groupie.jpg" alt="Groupie" class="project-image">
          <div :class="classes">
            <h3>Groupie.io</h3>
            <h4>Web + App Development</h4>
            <p>In-house project: Fan engagement mobile app for artists.</p>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      classes: ['card-content']
    }
  },
  methods: {
    hoverOver() {
      this.classes = ['animate__animated', 'animate__fadeIn', 'card-content', 'animate__faster']
    },
    hoverOut() {
      this.classes = ['animate__animated', 'animate__fadeOut', 'card-content', 'animate__faster']
    },
  }
}
</script>
